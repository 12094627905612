import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const FormTemplate = ({ onSendValue, handleShowReadMore, handleClose }) => {
    const [customerData, setCustomerData] = useState({ name: "", email: "" })
    const handleSubmit = async (e) => {
        onSendValue(true)
        handleShowReadMore(false)
        e.preventDefault()
        const response = await fetch('https://worrisome-pig-flannel-shirt.cyclic.cloud/api/customers/createcustomer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: customerData.name, email: customerData.email })
        });
        const json = await response.json()
        // response.send(json)
        handleClose()
    }
    const onChange = (e) => {
        setCustomerData({ ...customerData, [e.target.name]: e.target.value })
    }
    return (
        <>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="name" placeholder="Enter name" onChange={onChange} value={customerData.name} name="name" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" onChange={onChange} value={customerData.email} name="email" />
                    <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Button variant="primary" type="submit" onClick={handleSubmit} disabled={customerData.name.length < 1 || customerData.email.length < 5}>
                    Submit
                </Button>

            </Form>
        </>
    )
}

export default FormTemplate