import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RubberBand from 'react-reveal/RubberBand';
import rep1 from '../../images/rep1.png'
import LightSpeed from 'react-reveal/LightSpeed';
import Pulse from 'react-reveal/Pulse';

import Figure from 'react-bootstrap/Figure';
const PostReadMore = () => {
    return (
        <>
            <Row className='mt-5 px-4' style={{ fontSize: 35, fontFamily: "Arial" }} >
                <Col className='mt-3 text-light' >
                    <RubberBand >
                        <p><b><u>There is no secret.</u></b></p>
                    </RubberBand>
                    <p>Don’t boo me or get angry at me, I am not trying to clickbait you, I am telling you the ugly truth.</p>
                </Col>

            </Row>
            <Row className='px-5 text-light' style={{ fontSize: 35, fontFamily: "Arial" }}>
                <p>So many business owners are <b><u>hungry</u></b> for higher revenue and more customers constantly are under the belief that there is some secret.</p>
                <p>There is a trick though that could change your perspective on a sale.</p>
                <br />
            </Row>
            <Row className='bg-secondary pt-2 text-light' style={{ fontSize: 35, fontFamily: "Arial" }}>
                <p><b><u>The larger market formula</u></b></p>
                <p>The larger market formula is a concept that suggests a company's growth potential isn't solely determined by its current market share, but also by the overall expansion of the market itself. It emphasizes that as a market grows, even a smaller portion of it can lead to significant business opportunities and revenue.</p>
                <p>Ok too complicated? Sorry I will explain more simply.</p>
                <p className='px-5'><b>The larger market formula means that as more people start using a product or service, even a small piece of that big group can be a lot of customers and bring in lots of money.</b></p>
                <p><b><u>Ok you’re sick of reading this!</u></b></p>
                <Col>
                    <Figure>
                        <Figure.Image
                            width={600}
                            height={500}
                            alt="171x180"
                            src={rep1}
                        />
                        <LightSpeed left>

                            <Figure.Caption className='text-light'>
                                Here is a chart of the larger market formula.
                            </Figure.Caption>
                        </LightSpeed>

                    </Figure>
                    {/* <img src={rep1} alt='Diagram-1 here' height={500} width={600} /> */}
                </Col>
                <p className='mt-2'>Ok now I have gotten your email and you have read through all of this.</p>
                <br />
                <p> Expect to receive your FREE training plan in the next few days.</p>
                <p>If you would like to further your interest in growing your revenue.</p>
                <p>Contact me, and we will happily be your sidekick!</p>
                <Pulse>
                    <p className='mx-4'><b>We do SEO, Web development, Content creation, Marketing, Consulting</b></p>
                    <p><b>and if I didn’t mention something ask me and I will probably do it.</b></p>
                </Pulse>
            </Row>

        </>
    )
}

export default PostReadMore