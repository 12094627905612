import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';

import Header from './components/Header/Header';
import IntroSection from './components/IntroSection/IntroSection';
import PreReadMore from './components/PreReadMore/PreReadMore';
import PostReadMore from './components/PostReadMore/PostReadMore';
import Footer from './components/Footer/Footer';


import { useState } from 'react';

function App() {
  // const classes = useStyles()
  const [formSubmit, setFormSubmit] = useState('false')
  const handleFormSubmitValue = (value) => {
    setFormSubmit(value);
  };
  return (
    <>
      <div className="App" style={{ backgroundColor: "rgb(128,128,128)" }}>
        <Header />
        <Container>
          <IntroSection />
        </Container>
        <br />
        <br />
        <br />
        <PreReadMore onSendValue={handleFormSubmitValue} />
        {formSubmit === true ? <PostReadMore /> : ''}
        <br />
        <Footer />
      </div>
    </>
  );
}

export default App;
