import urflogo from '../../images/urftransparent.png'

import React from 'react'

const Footer = () => {
    return (
        <>

            <div className="container px-0 " style={{ maxWidth: '105%' }}>

                <footer
                    className="text-center bg-dark text-lg-start text-white"
                    style={{ backgroundColor: "" }}
                >
                    <div className="container p-4 pb-0">
                        <section className="">
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">
                                    </h6>
                                    <p>
                                        <img src={urflogo} height="200" width="250" />
                                    </p>
                                </div>

                                {/* <hr className="w-100 clearfix d-md-none" /> */}

                                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">

                                </div>

                                {/* <hr className="w-100 clearfix d-md-none" /> */}



                                {/* <hr className="w-100 clearfix d-md-none" /> */}

                                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-5">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
                                    <p><i className="fas fa-envelope mr-3"></i> michael@urfmedia.org</p>
                                    <p><i className="fas fa-phone mr-3"></i> +61 421952389</p>
                                    <a href="https://www.instagram.com/urfmedia/" style={{color:'white', textDecoration:'none'}} target='_blank'><p> <i className="fab fa-instagram mr-3"></i>    URF Media</p></a>
                                </div>
                            </div>
                        </section>

                        <hr className="my-3" />

                        <section className="p-3 pt-0">
                            <div className="row d-flex align-items-center">
                                <div className="col-md-7 col-lg-8 text-center text-md-start">
                                    <div className="p-3">
                                        © 2023 Copyright:
                                        <a className="text-white" href="http://localhost:3000"
                                        >URF Media</a
                                        >
                                    </div>
                                </div>

                                <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                                    <a
                                        className="btn btn-outline-light btn-floating m-1 text-white"
                                        role="button"
                                    ><i className="fab fa-facebook-f"></i
                                    ></a>

                                    <a
                                        className="btn btn-outline-light btn-floating m-1 text-white"
                                        role="button"
                                    ><i className="fab fa-twitter"></i
                                    ></a>

                                    <a
                                        className="btn btn-outline-light btn-floating m-1 text-white"
                                        role="button"
                                        href='mailto:michael@urfmedia.org'
                                    ><i className="fab fa-google"></i
                                    ></a>

                                    <a
                                        className="btn btn-outline-light btn-floating m-1 text-white"
                                        role="button"
                                        href='https://www.instagram.com/urfmedia/' target='_blank'
                                    ><i className="fab fa-instagram"></i
                                    ></a>
                                </div>
                            </div>
                        </section>
                    </div>
                </footer>
            </div>
        </>
    )
}

export default Footer