import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import urflogo from '../../images/urftransparent.png'

const Header = () => {
    return (
        <>
            <Navbar className="bg-dark">
                <Container className='py-0 my-0 align-item-center justify-content-center'>
                    <Navbar.Brand href="#home">
                        <img
                            src={urflogo}
                            width="100%"
                            height="200"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                    </Navbar.Brand>
                </Container>
            </Navbar>

        </>
    )
}

export default Header