import React,{useState} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTypewriter, Cursor } from 'react-simple-typewriter'
import ModalTemplate from '../ModalTemplate/ModalTemplate';

const PreReadMore = ({onSendValue}) => {
    const [text] = useTypewriter({
        words: ['The big secret is…'],
        loop: 1
    })
    return (
        <>
        <Row>
            <Col className='text-light' style={{ fontSize: 35, fontFamily: "Arial" }}>
                <p >So, you’re probably thinking at this point…</p>
                <p> "Ok I am on your website." </p>
                <p>What’s the secret to more revenue in my business?</p>
                Well without further or do,
                <br />
                <span style={{ fontSize: '33px', color: '#E87120' }}><b>{text}</b></span>
                <Cursor cursorStyle='.' cursorColor='yellow' />
                <br />
                <ModalTemplate onSendValue={onSendValue} />
            </Col>
        </Row>
        
        </>

    )
}

export default PreReadMore