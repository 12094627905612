import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormTemplate from '../FormTemplate/FormTemplate';

const ModalTemplate = ({ onSendValue }) => {
    const [show, setShow] = useState(false);
    const [showReadMoreBtn, setShowReadMoreBtn] = useState(true)
    const handleShowReadMoreBtn = (value) => {
        setShowReadMoreBtn(value)
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    console.log()
    return (
        <>
            {showReadMoreBtn === true ? <Button variant="primary" size='lg' onClick={handleShow}>
                Read More...
            </Button> : ''}


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>SignUp with Urf Media</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormTemplate onSendValue={onSendValue} handleShowReadMore={handleShowReadMoreBtn} handleClose={handleClose} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalTemplate