import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Typewriter from 'typewriter-effect';
import Card from 'react-bootstrap/Card';
import Zoom from 'react-reveal/Zoom';

const IntroSection = () => {
    return (
        <Row style={{ fontSize: 35, fontFamily: "Playfair Display SC" }}>

            {/* <Col>
                <Zoom left>
                    <Card
                        bg='warning'
                        style={{ width: '28rem', height: '28rem' }}
                        className="mb-2 mt-4"
                    >
                        <Card.Body>

                            <Card.Text>
                                <img src="https://cdni.iconscout.com/illustration/premium/thumb/man-working-on-laptop-2741035-2280943.png" alt="" />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Zoom>
            </Col> */}

            <Col className='text-light' style={{ marginTop: '60px' }}>
                <p style={{ fontSize: 40 }}>Your new favourite</p>
                <Typewriter
                    options={{
                        strings: ['SEO', 'Web development', 'Content Creation', 'Marketing', 'Consulting'],
                        autoStart: true,
                        loop: true,
                    }}
                />
                agency.

            </Col>
        </Row>
    )
}

export default IntroSection